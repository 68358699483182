@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* width */
  ::-webkit-scrollbar {
    @apply w-[6px];
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-darkColor-bold;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-primary-bold rounded;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-darkColor-extraLight;
  }

  html,
  body {
    scroll-behavior: smooth;
    /* overflow-x: hidden !important; */
    @apply bg-darkColor-bold overflow-x-hidden md:overflow-x-visible;
  }

  body {
    overflow-y: visible;
    position: relative;
    @apply md:overflow-x-hidden
    /* color: rgb(13, 13, 13); */;
  }
}

@layer components {
  .active_nav {
    box-shadow: 0 1px 0 #ffc451;
    @apply text-primary-bold font-medium;
  }

  .wrapper {
    @apply container mx-auto px-5;
  }

  .hover_animated_item:hover span {
    @apply text-[#FFC451];
  }

  .hover_animated_item:hover span.width_item {
    @apply md:w-1 md:mr-2;
  }

  .hover_animated_item.active .item_description {
    @apply absolute left-0 text-center w-full mt-1 md:static md:text-left md:w-56 md:mt-auto block opacity-100;
  }

  .hover_animated_item.active span {
    @apply text-[#FFC451];
  }

  .hover_animated_item.active span.width_item {
    @apply md:w-9 md:mr-2;
  }

  .hover_animation {
    @apply text-white font-normal font-headerTitle text-sm tracking-wide transition-all cursor-pointer sm:py-3 inline-block hover:text-[#FFC451];
  }

  hr {
    @apply border-[#363636];
  }

  .dark_shadow {
    box-shadow: 6px 6px #33ba73 !important;
  }

  /* insights styles */
  .article_card:hover .text_title {
    color: #ffc451 !important;
  }

  .article_card:hover .arrow_icon svg path {
    fill: #ffc451 !important;
  }
}

/* others styles */
.hover_item:hover svg path {
  stroke: #ffc451 !important;
}

.prev_next_btn:hover svg path {
  fill: #ffc451 !important;
}

.hover_svg:hover svg path {
  stroke: #ffc451;
  fill: #ffc451;
}

/* wise pivot */
.wise_pivot .slick-active.slick-current {
  outline: none !important;
  opacity: 1 !important;
}
.wise_pivot .slick-active {
  outline: none !important;
  opacity: 0.3;
}
.wise_pivot .slick-slide {
  opacity: 0.3;
}

.wish-pivot-slick-dots {
  display: flex !important;
  align-items: center !important;
  @apply gap-2 justify-end;
}

.wish-pivot-slick-dots li button {
  @apply w-[6px] md:w-5 lg:w-10 xl:w-20 h-[6px] rounded-full md:rounded-sm bg-[#29292C] text-xs text-transparent;
}

.wish-pivot-slick-dots li.slick-active button {
  @apply !bg-primary-bold;
}

.visualizing-slick-dots {
  transform: translateY(-50%);
  @apply !flex md:!block gap-2 flex-row absolute left-0 md:left-auto md:right-0 top-full md:top-1/2;
}

.at-glance {
  transform: translateY(0) !important;
  @apply !bottom-0 !top-auto mb-5;
}

.visualizing-slick-dots li button {
  @apply md:w-[6px] md:h-14 w-2 h-2 rounded-full md:rounded-sm bg-[#29292C] text-xs text-transparent mb-2 transition-all;
}

.visualizing-slick-dots li.slick-active button {
  @apply !bg-primary-bold md:h-16 h-2;
}

/* Teams */
.team_card:hover .twitter_link {
  @apply h-auto;
}

/* ========== */
.disruptions-slider-wrap .slick-list,
.upscale_team_wrap .slick-list {
  position: relative;
  display: block;
  overflow: visible;
  margin: 0;
  padding: 0;
}
.disruptions-slider-wrap,
.upscale_team_wrap {
  position: relative;
  width: 100%;
}
.disruptions-slider-wrap::after,
.upscale_team_wrap::after {
  content: "";
  width: 5000%;
  left: -5000%;
  position: absolute;
  top: 0;
  background: #0d0d0d;
  z-index: 30px;
  height: 100%;
}

.upscale_team_wrap::after {
  height: 110%;
}

.upscale_team_wrap .slick-slide[data-index="-1"] {
  width: 620px !important;
}
.upscale_team_wrap .slick-slide[data-index="0"] {
  width: 420px !important;
}
.upscale_team_wrap .slick-slide[data-index="1"] {
  width: 420px !important;
}
.upscale_team_wrap .slick-list {
  padding: 0 !important;
}
.upscale_team_wrap .slick-list .slick-track {
  height: 294px !important;
  overflow: hidden;
}

/* about_research */
.about_research .slick-slide {
  max-width: 450px;
}

.half_text {
  background: linear-gradient(to right, #0d0d0d 50%, #fff 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999999999999999999999999999999999999 !important;
}

.z-max {
  z-index: 9999999999999999999999999999999999999999999 !important;
}

/* test */

/* .team_card .lazy-load-image-background {
  @apply !max-h-[280px] !h-full;
} */

.team_card .lazy-load-image-background img {
  @apply md:!h-[300px] !object-top !h-[330px] !object-cover;
}
